<template>
  <v-app class="bg-grey-lighten-4">
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<style>
html {
  background-color: #f5f5f5 !important;
}

.v-messages__message {
  text-align: left !important;
  margin-bottom: 1em !important;
}
</style>
